import { DcBaseComponent } from '@deleteagency/dc';
import CustomAcitivityHelper from './custom-activity-helper';

export default class CustomActivityLogger extends DcBaseComponent {
    static getNamespace() {
        return 'custom-activity-logger';
    }

    onInit() {
        const isClickTriggered = this.options.onClick;

        if (!isClickTriggered) this._sendRequest();

        if (isClickTriggered) this.addListener(this.element, 'click', this._sendRequest.bind(this));
    }

    _sendRequest() {
        const { activityTitle, activityType, detailId, itemId, valueString } = this.element.dataset;

        let finalValueString = valueString;

        if (activityType == 'PropertySearchForSale' || activityType === 'PropertySearchToRent') {
            const searchId = sessionStorage.getItem('searchId');
            finalValueString = CustomAcitivityHelper.addItemsToValueString(valueString, {SearchId: searchId});
        }

        const params = {
            activityTitle,
            activityType,
            detailId,
            itemId,
            valueString: finalValueString,
        }

        CustomAcitivityHelper.send(params);
    }
}
